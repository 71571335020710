<template>
    <div class="mn__table__row">
        <div class="mn__table__cell" :class="{'without-partner': isPartner}">
            <span class="mn__table__cell__t mn__table__cell__t-1">{{ dateName }}</span>
            <span class="mn__table__cell__t">{{ date }}</span>
        </div>
        <div class="mn__table__cell" :class="{'without-partner': isPartner}">
            <PuSkeleton width="40px" v-if="isLoad" class="skeleton-input"></PuSkeleton>
            <span class="mn__table__cell__t"><span class="mn__table__cell__t-1">{{ count }}</span> шт</span>
            <PuSkeleton width="40px" v-if="isLoad" class="skeleton-input"></PuSkeleton>
            <span class="mn__table__cell__t"><span class="mn__table__cell__t-1">{{ amount }}</span> грн</span>
        </div>
        <div v-if="! isPartner" class="mn__table__cell">
            <PuSkeleton width="40px" v-if="isLoad" class="skeleton-input"></PuSkeleton>
            <span class="mn__table__cell__t"><span class="mn__table__cell__t-1">{{ countPartner }}</span> шт</span>
            <PuSkeleton width="40px" v-if="isLoad" class="skeleton-input"></PuSkeleton>
            <span class="mn__table__cell__t"><span class="mn__table__cell__t-1">{{ amountPartner }}</span> грн</span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        isPartner: {
            type: Boolean,
            default: () => {
                return true;
            }
        },
        isLoad: {
            type: Boolean,
            default: () => {
                return true;
            }
        },
        dateName: {
            type: String,
            default: () => {
                return null;
            }
        },
        date: {
            type: String,
            default: () => {
                return null;
            }
        },
        amount: {
            type: Number,
            default: () => {
                return 0;
            }
        },
        count: {
            type: Number,
            default: () => {
                return 0;
            }
        },
        amountPartner: {
            type: Number,
            default: () => {
                return null;
            }
        },
        countPartner: {
            type: Number,
            default: () => {
                return null;
            }
        },
    }
}
</script>
