<template>
    <div class="container">
        <div class="row">
            <div class="mn__box home-box">
                <order-statistic></order-statistic>
                <news></news>
                <earning-statistic></earning-statistic>
                <onmain-banner></onmain-banner>
<!--                <div class="mn__item statistic-preview-new">-->
<!--                    <div class="mn__in">-->
<!--                        <img :src="banner" style="width: 100%;object-fit: cover;height: 100%;"></div>-->
<!--                </div>-->
            </div>
        </div>
    </div>
</template>

<script>
import {OrderStatistic, EarningStatistic, News, OnmainBanner} from './components';

export default {
    components: {
        News,
        OrderStatistic,
        EarningStatistic,
        OnmainBanner
    },
    data: function () {
        return {
            banner: process.env.VUE_APP_API_ADMIN_SERVER + '/storage/prime/banner.jpg'
        }
    },

}
</script>
