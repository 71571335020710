<template>
    <div class="mn__item statistic-preview-new onmain-banner">
        <div class="mn__in omnain-wrap">
<!--            <img :src="banner" style="width: 100%;object-fit: cover;height: 100%;">-->
            <img class="wide" :src="getImgWide()" alt="icon" style="width: 100%;object-fit: cover;height: 100%;">
            <img class="desktop" :src="getImgDesktop()" style="width: 100%;object-fit: cover;height: 100%;">
            <img class="mobile" :src="getImgAdaptive()" style="height: 100%" alt="icon">
            <img class="tablet" :src="getImgTablet()" alt="icon">
        </div>
        <a :title="mainBanner['title'] || ''" :href="mainBanner['linkUA']" target="_blank" class="onmain-link"></a>
    </div>
</template>

<script>
 import newLoaderRow from './NewLoaderRow';
 import newRow from './NewRow';
 import { list } from '@/api/article';

 export default {
     components: {
         newRow,
         newLoaderRow
     },
     data: function () {
         return {
             adminApiUrl: process.env.VUE_APP_API_ADMIN_SERVER,
             defaultOnMainBanner: '/storage/prime/banner.jpg',
         }
     },
     computed: {
         mainBanner: function () {
             return this.$store.getters.ONMAIN_BANNER || {title: "", linkUA: ""};
         },
     },
     created: function() {
         this.$store.dispatch('GET_ONMAIN_BANNER');
         // let response = await list({limit: 4});
         // this.articles = response.data.data.items;
         // this.condition.isLoad = false;
     },
     methods: {
         styleObject: function () {
             let onmainBannerStyle = {
                 justifyContent: 'center'
             };
             if (this.mainBanner?.color) {
                 onmainBannerStyle.backgroundColor = '#' + this.mainBanner?.color;
             }
             return onmainBannerStyle;

         },
         getImgWide() {
             return this.adminApiUrl + (this.mainBanner?.imgWide ?? this.defaultOnMainBanner);
         },
         getImgDesktop() {
             //return this.mainBanner?.imgDesktop ?? this.defaultOnMainBanner
             return this.adminApiUrl + (this.mainBanner?.imgDesktop ?? this.defaultOnMainBanner);
         },
         getImgTablet() {
             return this.adminApiUrl + (this.mainBanner?.imgTablet ?? this.defaultOnMainBanner);
         },
         getImgAdaptive() {
             return this.adminApiUrl + (this.mainBanner?.imgAdaptive ?? this.defaultOnMainBanner);
         },
     },


 }

</script>
