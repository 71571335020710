<template>
    <div class="mn__news__item">
        <figure class="mn__news__item__img">
            <a href="javascript:void(0)" @click="show()">
                <img :src="preview" alt="" />
            </a>
        </figure>
        <div class="mn__news__item__content">
            <a @click="show()" href="javascript:void(0)" class="mn__news__item__content__title">
                {{ this.title}}
            </a>
            <div v-if="isDisableForPartner" class="mn__news__alert">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="8" cy="8" r="8" fill="#FFDDDB"/>
                    <rect x="7" y="3" width="2" height="6" fill="#EF4236"/>
                    <rect x="7" y="11" width="2" height="2" fill="#EF4236"/>
                </svg>
                Ця новина не відображається для ваших партнерів
            </div>
            <div class="mn__news__item__content__info">
                <a @click="show()" href="javascript:void(0)" class="mn__news__item__content__info__link">
                    Читати далі
                </a>
                <time v-if="publishedAt" class="mn__news__item__content__info__date">{{publishedAt}}</time>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        slug: {
            type: String,
            default: () => {
                return null;
            }
        },
        title: {
            type: String,
            default: () => {
                return null;
            }
        },
        preview: {
            type: String,
            default: () => {
                return null;
            }
        },
        publishedAt: {
            type: String,
            default: () => {
                return null;
            }
        },
        isDisableForPartner: {
            type: Boolean,
            default: () => {
                return false;
            }
        }
    },
    methods: {
        show: function() {
            this.$router.push({name: 'newShow', params: {slug: this.slug}})
        }
    }
}
</script>
