<template>
    <div class="mn__item">
        <p class="mn__item__title">
            <a class="mn__item__title__link" target="_blank">Сума та кількість проданих полісів</a>
        </p>
        <div class="mn__in">
            <div class="mn__in__label" data-toggle="collapse" data-target="#mn1" aria-expanded="true" aria-controls="mn1" >
                <span>Всі поліси</span>
                <svg width="22" height="12" viewBox="0 0 22 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0)">
                        <path d="M1.441 8.98662e-07L-4.63781e-07 1.38992L11 12L22 1.38992L20.559 6.29881e-08L10.9945 9.22546L1.441 8.98662e-07Z" fill="white"/>
                    </g>
                    <defs>
                        <clipPath id="clip0">
                            <rect width="12" height="22" fill="white" transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 22 12)"/>
                        </clipPath>
                    </defs>
                </svg>
            </div>
            <div id="mn1" class="mn__in__body mn__in__body-np collapse show">
                <div class="mn__table__wrapper">
                    <div class="mn__table">
                        <div class="mn__table__row mn__table__row-head">
                            <div class="mn__table__cell" :class="{'without-partner': isPartner()}">Дата</div>
                            <div class="mn__table__cell" :class="{'without-partner': isPartner()}">Вами</div>
                            <div v-if="! isPartner()" class="mn__table__cell">Партнерами</div>
                        </div>

                        <order-statistic-row
                            date-name="Сьогодні"
                            :date="getToday('DD.MM')"
                            :is-load="condition.isLoad"
                            :amount="showAmount('today')"
                            :count="showCount('today')"
                            :count-partner="showCountPartner('today')"
                            :amount-partner="showAmountPartner('today')"
                            :is-partner="this.isPartner()">
                        </order-statistic-row>
                        <order-statistic-row
                            date-name="Вчора"
                            :date="getYesterday('DD.MM')"
                            :is-load="condition.isLoad"
                            :amount="showAmount('yesterday')"
                            :count="showCount('yesterday')"
                            :count-partner="showCountPartner('yesterday')"
                            :amount-partner="showAmountPartner('yesterday')"
                            :is-partner="this.isPartner()">
                        </order-statistic-row>
                        <order-statistic-row
                            date-name="Тиждень"
                            :date="getStartOfWeek('DD.MM') + ' - ' + getEndOfWeek('DD.MM')"
                            :is-load="condition.isLoad"
                            :amount="showAmount('week')"
                            :count="showCount('week')"
                            :count-partner="showCountPartner('week')"
                            :amount-partner="showAmountPartner('week')"
                            :is-partner="this.isPartner()">
                        </order-statistic-row>
                        <order-statistic-row
                            date-name="Місяць"
                            :date="getStartOfMonth('DD.MM') + ' - ' + getEndOfMonth('DD.MM')"
                            :is-load="condition.isLoad"
                            :amount="showAmount('month')"
                            :count="showCount('month')"
                            :count-partner="showCountPartner('month')"
                            :amount-partner="showAmountPartner('month')"
                            :is-partner="this.isPartner()">
                        </order-statistic-row>

                        <div class="mn__table__row mn__table__row-ps">
                            <PuSkeleton v-if="condition.isLoad" class="skeleton-input"></PuSkeleton>
                            <span class="mn__table__row-ps__item">шт — Кількість проданих полісів</span>
                            <PuSkeleton v-if="condition.isLoad" class="skeleton-input"></PuSkeleton>
                            <span class="mn__table__row-ps__item">грн — Загальна вартість</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { dateMixin, userMixin } from '@/mixin';
import { orderPurchases } from '@/api/statistics';
import OrderStatisticRow from "./OrderStatisticRow";
import {ClientInfo, MoreOptions, RowBlockSkeleton, RowMobileBlockSkeleton} from "../../history/components";

export default {
    components: { OrderStatisticRow },
    mixins: [dateMixin, userMixin],
    data: function () {
        return {
            condition: {
                isLoad: true
            },
            data: {
                personal: {},
                partner: {},
            }
}
    },
    created: async function() {
        let response = await orderPurchases();

        if (response.data?.data?.personal) {
            this.data = response.data.data;
            this.condition.isLoad = false;
        }
    },
    methods: {
        showAmount: function(period) {
            return this.data.personal?.[period]?.result?.amount;
        },
        showCount: function(period) {
            return this.data.personal?.[period]?.result?.count;
        },
        showCountPartner: function(period) {
            return this.data?.partner?.[period]?.result?.count;
        },
        showAmountPartner: function(period) {
            return this.data?.partner?.[period]?.result?.amount;
        }
    }
}
</script>
