<template>
    <div class="mn__item">
        <div class="mn__item__title earnings_top">
            <div class="mn__item__title__link" target="_blank">Заробіток</div>
            <div class="earnings_top_nav" v-if="! isPartner()">
                <div class="earnings_top_links">
                    <span>Відображати заробіток:</span>
                    <a href="javascript:void(0)" @click="showEarnings(null); sortType='my'; earningUserId = null;" :class="{'active' : this.sortType === 'my'}">Мій</a>
                    <a v-if="partnersList" href="javascript:void(0)" @click="showSystemEarnings(); sortType = 'system'; earningUserId = null;" :class="{'active' : this.sortType === 'system'}">Системи</a>
                </div>
                <el-select v-model="earningUserId"
                            @change="sortType='user'"
                            :class="{'active' : this.sortType === 'user'}"
                            placeholder="Подточки">
                    <el-option v-for="item in partnersList"
                                :key="item.id"
                                :label="item.fullName"
                                :value="item.id">
                    </el-option>
                </el-select>
            </div>


        </div>
        <div class="mn__in mn__in-nb">
            <div class="mn__in__body mn__in__body-no">
                <div class="mn__grf">
                    <earning-statistic-row date-name="Сьогодні"
                                           :date="getToday('DD.MM')"
                                           :is-load="condition.isLoad"
                                           :amount="showAmount('today')"
                    ></earning-statistic-row>
                    <earning-statistic-row date-name="Вчора"
                                           :date="getYesterday('DD.MM')"
                                           :is-load="condition.isLoad"
                                           :amount="showAmount('yesterday')"
                    ></earning-statistic-row>
                    <earning-statistic-row date-name="Тиждень"
                                           :date="getStartOfWeek('DD.MM') + ' - ' + getEndOfWeek('DD.MM')"
                                           :is-load="condition.isLoad"
                                           :amount="showAmount('week')"
                    ></earning-statistic-row>
                    <earning-statistic-row date-name="Місяць"
                                           :date="getStartOfMonth('DD.MM') + ' - ' + getEndOfMonth('DD.MM')"
                                           :is-load="condition.isLoad"
                                           :amount="showAmount('month')"
                    ></earning-statistic-row>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import EarningStatisticRow from "./EarningStatisticRow";
import { earningIncome } from '@/api/statistics';
import { dateMixin,  userMixin, partnerMixin } from '@/mixin';

export default {
    mixins: [dateMixin,userMixin, partnerMixin],
    components: {
        EarningStatisticRow
    },
    data: function () {
        return {
            data: [],
            earningUserId: null,
            condition: {
                isLoad: true
            },
            sortType: 'my',
        }
    },
    computed: {
        partnersList() {
            return this.$store.getters.ALL_PARTNERS;
        }
    },
    created: function() {
        this.showEarnings(null);
    },

    methods: {
        showSystemEarnings: async function() {
            this.showEarnings(this.$_.pluck(this.partnersList, 'id'));
        },
         showEarnings:async function (ids = null) {
             this.condition.isLoad = true;
            let response = await earningIncome(ids);

            if (response.data?.data) {
                // this.$nextTick(function () {
                    this.data = Object.assign(response.data.data);

                // })
                // console.log(this.data);
                // console.log(this.showAmount('yesterday'));
            }
             this.condition.isLoad = false;

         },
        showAmount: function(period) {
            return this.data?.[period]?.result?.amount;
        },
    },

    watch: {
        earningUserId: function(newValue) {
            this.showEarnings([newValue]);
        }
    }
}
</script>
