<template>
    <div class="mn__grf__item">
        <div class="mn__grf__item__circle">
            <div v-if="isLoad" class="spinner-border option-loader" role="status"></div>
            <span v-else class="mn__grf__item__circle__number">{{ amount }}</span>
            <span class="mn__grf__item__circle__text">грн</span>
        </div>
        <div class="mn__grf__item__content">
            <p class="mn__grf__item__content__title">{{ dateName }}</p>
            <time class="mn__grf__item__content__date">{{ date }}</time>
        </div>
    </div>
</template>


<script>
export default {
    props: {
        isLoad: {
            type: Boolean,
            default: () => {
                return true;
            }
        },
        dateName: {
            type: String,
            default: () => {
                return null;
            }
        },
        date: {
            type: String,
            default: () => {
                return null;
            }
        },
        amount: {
            type: Number,
            default: () => {
                return null;
            }
        }
    }
}
</script>
